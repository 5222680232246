// @ts-nocheck
import ComputeJob from './ComputeJob.gql';
import ComputeTaskFileRepositories from './ComputeTaskFileRepositories.gql';
import ComputeTaskLog from './ComputeTaskLog.gql';
import CreateComputeJob from './CreateComputeJob.gql';
import CreateComputeTask from './CreateComputeTask.gql';
import CreateJob from './CreateJob.gql';
import DeleteJob from './DeleteNode.gql';
import GetComputeWorkspace from './GetComputeWorkspace.gql';
import GetFeatureFlags from './GetFeatureFlags.gql';
import GetJobTypes from './GetJobTypes.gql';
import GetSystemJobTemplates from './GetSystemJobTemplates.gql';
import GetUserBalance from './GetUserBalance.gql';
import GetWorkspace from './GetWorkspace.gql';
import IssueFrsFileRepositoryDownloadTickets from './IssueFrsFileRepositoryDownloadTickets.gql';
import IssueFrsFileRepositoryUploadTickets from './IssueFrsFileRepositoryUploadTickets.gql';
import Job from './Job.gql';
import SubmitComputeJob from './SubmitComputeJob.gql';
import SubmitJob from './SubmitJob.gql';
import TerminateComputeJob from './TerminateComputeJob.gql';
import TerminateJob from './TerminateJob.gql';
import UpdateUserEntitlementData from './UpdateUserEntitlementData.gql';
import UserEntitlementData from './UserEntitlementData.gql';

export {
   GetUserBalance,
   DeleteJob,
   GetJobTypes,
   ComputeJob,
   Job,
   GetComputeWorkspace,
   GetWorkspace,
   TerminateJob,
   TerminateComputeJob,
   UpdateUserEntitlementData,
   UserEntitlementData,
   IssueFrsFileRepositoryDownloadTickets,
   IssueFrsFileRepositoryUploadTickets,
   ComputeTaskFileRepositories,
   CreateComputeJob,
   CreateComputeTask,
   SubmitComputeJob,
   ComputeTaskLog,
   GetFeatureFlags,
   GetSystemJobTemplates,
   CreateJob,
   SubmitJob,
};
