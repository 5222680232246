// @ts-nocheck

/* eslint-disable max-lines */
import {
   __,
   ap,
   append,
   assocPath,
   compose,
   evolve,
   hasPath,
   ifElse,
   includes,
   isNotNil,
   map,
   mergeDeepLeft,
   mergeLeft,
   path,
   pipe,
   prop,
   propEq,
   propSatisfies,
   tap,
   update,
   when,
} from 'ramda';

import { getClientAndSignedUrl, getDownloadTicket } from '@/store/hpcJob/files';

import { getJobTypeAndId } from '@/utils/accessors';
import { hasID } from '@/utils/comparators';
import {
   computeTaskUnifiedStatus,
   dataStatePath,
   dataStates,
   errorTypes,
   jobTypeKeys,
   jobTypeNames,
   remoteTypes,
   sortDirection,
} from '@/utils/constants';
import { GQLError } from '@/utils/customErrors';
import { sendEmbeddedMessage } from '@/utils/embeddedMessage';
import { getFileKey } from '@/utils/embeddedUtils';
import { rejectByID } from '@/utils/filters';
import { applyToJobsInWorkspace, updateJobs } from '@/utils/formatters';
import { flipDirection } from '@/utils/utils';

import { oldTrackTermination, trackDeletion, trackTermination } from '@/analytics/events';
import { refinerTrackRemoteDeletion, refinerTrackRemoteTermination } from '@/analytics/refiner';

import { errors } from './errors';
import services from './services';
import { store } from './state';

// Creates a function that tests whether a given job object matches any of the id's in the given jobIds array
const containsJob = (jobIds) => propSatisfies(includes(__, jobIds), 'id');

const setTaskStatus = (job, status) =>
   compose(
      mergeLeft({
         unifiedStatus: status,
      }),
      path(['tasks', '0']),
   )(job);
const updateJobWithTasks = (status) => (job) => {
   const updatedTask = setTaskStatus(job, status);
   return mergeLeft({ tasks: [updatedTask] }, job);
};
const updateJob = ({ jobIds, status }) => when(containsJob(jobIds), updateJobWithTasks(status));

const updateJobsList = compose(applyToJobsInWorkspace, updateJob);

const oldFormatResponseForPresent = pipe(
   path(['data', 'terminateComputeJob', 'job']),
   updateJobs,
   assocPath(['workspace', 'jobs'], __, { workspace: {} }),
   evolve,
);

const formatResponseForPresent = pipe(
   path(['data', 'terminateJob', 'job']),
   updateJobs,
   assocPath(['workspace', 'jobs'], __, { workspace: {} }),
   evolve,
);

const sendEmbeddedMessageForEachFile = (message, files, downloadInfo, repositoryId) => {
   files.forEach((file) => {
      const value = downloadInfo[getFileKey(file.filename, repositoryId)];
      if (isNotNil(value)) {
         sendEmbeddedMessage(message, { url: value.url });
      }
   });
};

const oldTerminationTrackers = (res) => {
   const { jobTypeName, id } = path(['data', 'terminateComputeJob', 'job'], res);
   oldTrackTermination(window.San, { jobTypeName, id });

   if (jobTypeName === jobTypeNames.remote) {
      refinerTrackRemoteTermination();
   }
};

const terminationTrackers = (res) => {
   const { jobType } = path(['data', 'terminateJob', 'job', 'jobDefinition'], res);
   const { id } = path(['data', 'terminateJob', 'job'], res);
   trackTermination(window.San, { jobType, id });

   if (jobType === jobTypeKeys.remote) {
      refinerTrackRemoteTermination();
   }
};

const deletionTrackers = (job) => {
   const { jobTypeName, id } = getJobTypeAndId(job);
   trackDeletion(window.San, { jobTypeName, id });
   if (remoteTypes.includes(jobTypeName)) {
      refinerTrackRemoteDeletion();
   }
};

const updateTaskOrJobStatus = (id, status) =>
   map(
      when(
         propEq(id, 'resourceId'),
         ifElse(
            hasPath(['tasks']),
            mergeDeepLeft({ tasks: update(0, setTaskStatus(__, status), prop('tasks', __)) }),
            mergeDeepLeft({ status }),
         ),
      ),
   );

export const rawActions = (present) => ({
   loadComputeJobs: (client, poll = false) => {
      present({ op: 'replace', value: dataStates.loading, path: dataStatePath });

      return services
         .getComputeWorkspace(client)
         .then((workspace) => {
            present(
               [
                  { op: 'replace', value: workspace, path: '/workspace' },
                  { op: 'replace', value: dataStates.ready, path: dataStatePath },
               ],
               { client, poll },
            );
         })
         .catch(() => {
            present({ op: 'replace', value: dataStates.error, path: dataStatePath }, { client, poll });
         });
   },

   loadJobs: (client, poll = false) => {
      present({ op: 'replace', value: dataStates.loading, path: dataStatePath });

      return services
         .getWorkspace(client)
         .then((workspace) => {
            present(
               [
                  { op: 'replace', value: workspace, path: '/workspace' },
                  { op: 'replace', value: dataStates.ready, path: dataStatePath },
               ],
               { client, poll },
            );
         })
         .catch(() => {
            present({ op: 'replace', value: dataStates.error, path: dataStatePath }, { client, poll });
         });
   },

   deleteJobs: (client, jobs) => {
      const jobIds = jobs.map(prop('id'));
      present(updateJobsList({ jobIds, status: computeTaskUnifiedStatus.Deleting }));

      return services
         .deleteJobs(client, jobIds)
         .then(() => {
            // TODO - this is some derived data, ideally done in the nap or a separate action
            // called from the nap
            ap([store.actions.deselectJob, deletionTrackers], jobs);
            present(updateJobsList({ jobIds, status: computeTaskUnifiedStatus.Deleted }));
         })
         .catch((err) => {
            // TODO - updating the 'errorstate' for compatibility, but this should
            // be reviewed and a better solution applied
            // TODO : this action is unreachable for its purpose,
            // an action error will not throw but return an errors key in the response object
            errors.updateErrorsState(new GQLError(errorTypes.jobDeleteError, err));
         });
   },

   addJob: (job) => present({ op: 'add', value: job, path: '/workspace/jobs/-' }),

   updateJob: compose(present, applyToJobsInWorkspace),

   terminateComputeJob: (client, job) => {
      present(updateJobsList({ jobIds: [job.id], status: computeTaskUnifiedStatus.Terminating }));

      return services
         .terminateComputeJob(client, job.id)
         .then(tap(oldTerminationTrackers))
         .then(oldFormatResponseForPresent)
         .then(present(updateJobsList({ jobIds: [job.id], status: computeTaskUnifiedStatus.Terminated })))
         .catch(errors.updateErrorsState);
   },

   terminateJob: (client, job) => {
      present(updateJobsList({ jobIds: [job.id], status: computeTaskUnifiedStatus.Terminating }));

      return services
         .terminateJob(client, job.id)
         .then(tap(terminationTrackers))
         .then(formatResponseForPresent)
         .then(present(updateJobsList({ jobIds: [job.id], status: computeTaskUnifiedStatus.Terminated })))
         .catch(errors.updateErrorsState);
   },

   selectJob: (job) => {
      present((state) => {
         const selection = ifElse(hasID(job.id), rejectByID(job.id), append(job))(state.selection);

         return mergeLeft({ selection }, state);
      });
   },

   deselectJob: (job) => {
      present((state) => {
         const selection = rejectByID(job.id)(state.selection);
         return mergeLeft({ selection }, state);
      });
   },

   clearSelection: () => {
      present({ op: 'replace', value: [], path: '/selection' });
   },

   setSorting: (accessor, direction = sortDirection.up) => {
      present({ op: 'replace', value: { accessor, direction }, path: '/sorting' });
   },

   flipSortingDirection: () => {
      present((state) => mergeDeepLeft({ sorting: { direction: flipDirection(state.sorting.direction) } }, state));
   },

   setPage: (page) => {
      present({ op: 'replace', value: page, path: '/page' });
   },

   setWarnUserInfo: (value) => {
      sessionStorage.setItem('warnUserRemoteSession', value);
      present({ op: 'replace', value, path: '/warnUserRemoteSession/userWantsToBeWarned' });
   },

   getWarnInfo: () => {
      const warnUser = JSON.parse(sessionStorage.getItem('warnUserRemoteSession'));

      if (isNotNil(warnUser)) {
         present({ op: 'replace', value: warnUser, path: '/warnUserRemoteSession/userWantsToBeWarned' });
      }
   },

   test: {
      initWorkspaceState: (data) => {
         present({ op: 'replace', value: data, path: '' });
      },
   },

   downloadFilesEmbedded: (client, downloadPath, repoId) => (files) => {
      Promise.all(
         files.map(({ filename, repositoryId }) =>
            getDownloadTicket(client, repositoryId)
               .then(getClientAndSignedUrl(filename))
               .then((url) => ({ filename: filename, url: url })),
         ),
      ).then((fileInfos) => {
         const tickets = {
            // repositoryId, like it is used here, is only an unique id for sending embedded messages,
            // it does not matter if we always use the id of the inputRepo or outputRepo or any other id
            repositoryId: repoId,
            downloadPath,
            files: fileInfos,
         };
         sendEmbeddedMessage('fileTransfer/startDownload', tickets);
      });
   },

   cancelDownload: (downloadInfo, repositoryId) => (files) =>
      sendEmbeddedMessageForEachFile('fileTransfer/cancelDownload', files, downloadInfo, repositoryId),

   pauseDownload: (downloadInfo, repositoryId) => (files) =>
      sendEmbeddedMessageForEachFile('fileTransfer/pauseDownload', files, downloadInfo, repositoryId),

   resumeDownload: (downloadInfo, repositoryId) => (files) =>
      sendEmbeddedMessageForEachFile('fileTransfer/resumeDownload', files, downloadInfo, repositoryId),

   setDownloadInfo: (fileKey, value) => {
      present({ op: 'add', value, path: `/downloadInfo/${fileKey}` });
   },

   setOverallDownloadInfo: (repositoryId, value) => {
      present({ op: 'add', value, path: `/overallDownloadInfo/${repositoryId}` });
   },

   setDownloadPathEmbedded: (repositoryId, downloadPath) => {
      sendEmbeddedMessage('fileTransfer/setDownloadPath', { repositoryId, downloadPath });
   },

   storeDownloadPath: (repositoryId, downloadPath) => {
      present({ op: 'add', value: downloadPath, path: `/downloadPath/${repositoryId}` });
   },

   setJobStatus: (id, status) => {
      present(
         evolve({
            workspace: {
               jobs: updateTaskOrJobStatus(id, status),
            },
         }),
      );
   },
   // only for testing
   clearDownloadInfo: () => {
      present({ op: 'replace', value: {}, path: '/downloadInfo' });
   },
});
