import hpcJobIcon from '/img/hpcJob.svg';
import remoteDesktopIcon from '/img/remoteDesktopJob.svg';
import { cond, path, propEq, T } from 'ramda';

import { StatusTag } from '@/modules/listing/components/StatusTag';

import { getStatus } from '@/utils/accessors';
import { jobTypeNames } from '@/utils/constants';
import { formatRunTime, formattedTime } from '@/utils/formatters';

const iconMap = {
   [jobTypeNames.remote]: remoteDesktopIcon,
   [jobTypeNames.hpc]: hpcJobIcon,
};

const BaseCell = ({ children, ...props }) => (
   <td className="cursor-pointer break-all" {...props}>
      {children}
   </td>
);

const IconCell = ({ src, dataAccessor, ...imgProps }) => (
   <BaseCell data-accessor={dataAccessor}>
      <div className="flex justify-center items-center">
         <img src={src} width="24" {...imgProps} />
      </div>
   </BaseCell>
);

export const OldTableCells = (job, t) => {
   return cond([
      [
         propEq('status', 'label'),
         () => (
            <BaseCell key={crypto.randomUUID()} data-accessor="status">
               <StatusTag status={getStatus(job)} />
            </BaseCell>
         ),
      ],
      [
         propEq('type', 'label'),
         (accessor) => (
            <IconCell
               key={crypto.randomUUID()}
               src={iconMap[path(accessor.path, job)]}
               title={job.jobTypeName}
               alt={job.jobTypeName}
               dataAccessor="type"
            />
         ),
      ],
      [
         propEq('submitted', 'label'),
         () => {
            const today = new Date();
            const timeFormatted = formattedTime(job.submissionTime, today);

            return (
               <BaseCell key={crypto.randomUUID()} data-accessor="submitted">
                  {timeFormatted.translationKey
                     ? t(`job.${timeFormatted.translationKey}`, { time: timeFormatted.time })
                     : timeFormatted}
               </BaseCell>
            );
         },
      ],
      [
         propEq('runTime', 'label'),
         () => {
            return (
               <BaseCell key={crypto.randomUUID()} data-accessor="runTime">
                  {formatRunTime(job, t)}
               </BaseCell>
            );
         },
      ],
      [
         propEq('name', 'label'),
         () => (
            <BaseCell key={crypto.randomUUID()} title={job.name} data-accessor="name">
               {job.name}
            </BaseCell>
         ),
      ],
      [
         T,
         (accessor) => (
            <BaseCell key={crypto.randomUUID()} data-accessor={accessor.label}>
               {path(accessor.path)(job)}
            </BaseCell>
         ),
      ],
   ]);
};
