import { AwsRum } from 'aws-rum-web';

import { requiredEnvVars, rumErrors, rumTelemetries } from './constants';

/**
 * Retrieves the required environment variables for initializing the AWS CloudWatch RUM web client.
 * @returns {object} An object containing the required environment variable values.
 * @throws {Error} If any of the required environment variables are missing.
 */
const getRequiredEnvVars = () => {
   const missingEnvVars = requiredEnvVars.filter((variable) => !import.meta.env[variable]);
   if (missingEnvVars.length > 0) {
      throw new Error(`${rumErrors.missingVars}: ${missingEnvVars.join(', ')}`);
   }

   return requiredEnvVars.reduce((result, variable) => {
      result[variable] = import.meta.env[variable];
      return result;
   }, {});
};

/**
 * Initializes the AWS CloudWatch RUM web client.
 * @returns {AwsRum|null} The initialized AwsRum instance, or null if an error occurs.
 */
export const initializeAwsRum = () => {
   try {
      const {
         VITE_RUM_IDENTITY_POOL_ID,
         VITE_RUM_ENDPOINT,
         VITE_RUM_APPLICATION_ID,
         VITE_RUM_APPLICATION_VERSION,
         VITE_RUM_APPLICATION_REGION,
      } = getRequiredEnvVars();

      // AWS CloudWatch RUM Configuration
      const config = {
         sessionSampleRate: 1,
         identityPoolId: VITE_RUM_IDENTITY_POOL_ID,
         endpoint: VITE_RUM_ENDPOINT,
         telemetries: rumTelemetries,
         allowCookies: false,
         enableXRay: false,
      };

      return new AwsRum(VITE_RUM_APPLICATION_ID, VITE_RUM_APPLICATION_VERSION, VITE_RUM_APPLICATION_REGION, config);
   } catch (error) {
      console.error(rumErrors.errorInitilize, error);
      return null;
   }
};
